export const getFile = async ({ id, bucket }) => {
  try {
    const response = await fetch(`/api/storage/${bucket}/${id}`);
    const file = await response.json();
    return {
      status: response.status,
      ...file,
    };
  } catch (e) {
    // console.log({ e });
  }
};

export const uploadFile = async ({ file, id, bucket }) => {
  let data = new FormData();
  data.append("file", file);

  try {
    const response = await fetch(`/api/storage/${bucket}/${id}`, {
      method: "PUT",
      body: data,
    });
    if (response.status === 200) {
      const data = await response.json();
      return {
        status: response.status,
        ...data,
      };
    } else if (response.status === 500) {
      return {
        status: response.status,
        message:
          "Something went wrong :(. Check your file size, cannot exceed 5mb.",
      };
    }
  } catch (e) {
    // console.log({ e });
  }
};

export const deleteFile = async ({ id, bucket }) => {
  try {
    const response = await fetch(`/api/storage/${bucket}/delete/${id}`, {
      method: "PUT",
    });
    const data = await response.json();
    return data.message;
  } catch (e) {
    // console.log({ e });
  }
};

export const convertToBase64 = (file) => {
  const pngPrefix = "data:image/png;base64,";
  const jpgPrefix = "data:image/jpg;base64,";
  const jpegPrefix = "data:image/jpeg;charset=utf-8;base64,";
  const pdfPrefix = "data:application/pdf;base64,";

  if (file.message === "File not found") return "";

  // remove any preexisting prefixes
  // 4/12/2023 not fully sure about these changes but
  // we support more than just png / pdf
  // so prefixes should too??
  // TO DO investigate further, related to current_user_avatar localStorage limitation
  let sanitizedString = file.str && file.str.replace(pngPrefix, "");
  sanitizedString = sanitizedString && sanitizedString.replace(jpgPrefix, "");
  sanitizedString = sanitizedString && sanitizedString.replace(jpegPrefix, "");
  sanitizedString = sanitizedString && sanitizedString.replace(pdfPrefix, "");

  let base64String;
  if (file.extension === "png") base64String = pngPrefix + sanitizedString;
  if (file.extension === "jpg") base64String = jpgPrefix + sanitizedString;
  if (file.extension === "jpeg") base64String = jpegPrefix + sanitizedString;
  if (file.extension === "pdf") base64String = pdfPrefix + sanitizedString;

  return base64String;
};
// 22 upvoted answer here
// https://stackoverflow.com/questions/20379027/javascript-reduce-the-size-and-quality-of-image-with-based64-encoded-code
/**
 * Resize a base 64 Image
 * @param {String} base64 - The base64 string (must include MIME type)
 * @param {Number} newWidth - The width of the image in pixels
 * @param {Number} newHeight - The height of the image in pixels
 */
export const resizeBase64Img = (base64, newWidth, newHeight) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    canvas.width = newWidth;
    canvas.height = newHeight;
    let context = canvas.getContext("2d");
    let img = document.createElement("img");
    img.src = base64;
    img.onload = function () {
      context.scale(newWidth / img.width, newHeight / img.height);
      context.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
  });
};
