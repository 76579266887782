import { useState, useEffect, useCallback, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useTheme,
  Tooltip,
} from "@mui/material";
import { NewTripDialogTitle } from "./";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TripFields1 } from "../";
import { fetchTripsForUser, saveTrip } from "../../utils/trip_utils";
import { AppContext } from "../../contexts";
import { useParams, useNavigate } from "react-router-dom";
import { addDays, utcDate } from "../../utils/date_utils";
import { RequiredFieldsTooltip } from "../../constants";

export const NewTripDialog = ({ open, handleClose, isFirstTrip }) => {
  const { trips, userId, setTrips, tripLocation } = useContext(AppContext);
  const { trip_id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [title, setTitle] = useState("");
  const [location, setLocation] = useState(tripLocation || "");
  // https://github.com/mui/material-ui-pickers/pull/1770
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [lat, setLat] = useState(undefined);
  const [lng, setLng] = useState(undefined);
  const [description, setDescription] = useState("");
  const [savedTrip, setSavedTrip] = useState(undefined);
  const [loading, setLoading] = useState(false);

  // TO DO is this even necessary
  // in what cases is there a trip here?
  useEffect(() => {
    if (trips) {
      // TO DO refactor to trip
      const trip = trips.find((trips) => trips.id === trip_id);
      if (trip) {
        setTitle(trip.title);
        setStartDate(utcDate(trip.start_date));
        setEndDate(utcDate(trip.end_date));
        setLocation(trip.location);
        setDescription(trip.description);
      }
    }
  }, [trips, trip_id]);

  // useEffect(() => {
  //   if (startDate && endDate === null) {
  //     // assume initial trip length of 7 days,
  //     // fixes strange issue with minDate=startDate disabling endDate
  //     setEndDate(utcDate(addDays(startDate, 7)));
  //   }
  // }, [startDate, endDate]);

  const reinitializeState = useCallback(() => {
    setTitle("");
    setLocation("");
    setStartDate(null);
    setEndDate(null);
    setLat(undefined);
    setLng(undefined);
    setDescription("");
    setSavedTrip(undefined);
  }, []);

  // once closed, reinitialize state
  useEffect(() => {
    if (!open) {
      reinitializeState();
    }
  }, [open, reinitializeState]);

  useEffect(() => {
    const savedTripAdded = trips.find((trips) => trips.id === savedTrip?.id);
    if (savedTripAdded) {
      navigate(`/trip/${savedTripAdded.id}`);
    }
  }, [navigate, savedTrip, trips]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      PaperProps={{
        component: "form",
      }}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
    >
      <NewTripDialogTitle
        handleClose={handleClose}
        isFirstTrip={isFirstTrip}
        loading={loading}
      />
      <DialogContent>
        <TripFields1
          title={title}
          setTitle={setTitle}
          location={location}
          setLocation={setLocation}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setLat={setLat}
          setLng={setLng}
          callee="dialog"
        />
      </DialogContent>
      <DialogActions>
        <Tooltip
          title={
            title && location && startDate && endDate
              ? ""
              : RequiredFieldsTooltip
          }
        >
          <span>
            <Button
              variant="contained"
              onClick={() => {
                setLoading(true);
                saveTrip({
                  title,
                  startDate,
                  endDate,
                  userId,
                  tripId: trip_id,
                  location,
                  lat,
                  lng,
                  description,
                }).then((result) => {
                  if (result) {
                    setSavedTrip(result);
                    fetchTripsForUser({ userId }).then((trips) => {
                      if (trips) setTrips(trips);
                    });
                  }
                });
              }}
              disabled={
                title &&
                location &&
                startDate &&
                endDate &&
                endDate > startDate &&
                !loading
                  ? false
                  : true
              }
            >
              Save
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
