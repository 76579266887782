import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import MapIcon from "@mui/icons-material/Map";
import {
  EmptyState,
  GridView,
  Loader,
  ProfileSummary,
  MapView,
  ToggleViewBar,
  TripsTable,
} from "./";
import { CropPhotoDialog } from "./Modals";
import {
  allTripColumns,
  TripsTableTooltip,
  TripsMapTooltip,
  TripsGridTooltip,
  UserHasNoPublicTripsExplanation,
} from "../constants";
import {
  getFollowerCount,
  getFollowingCount,
  getFollowers,
  getFollowing,
  followUser,
  unfollowUser,
  removeFollower,
  getSuggestedProfiles,
} from "../utils/follow_utils";
import { fetchCollaboratorsForTrip } from "../utils/collaborator_utils";
import {
  getFile,
  uploadFile,
  convertToBase64,
  resizeBase64Img,
} from "../utils/storage_utils";
import { fetchTripOwner, setLocalStorageItem } from "../utils/user_utils";
import { fetchTripsForUser, retrieveCoverPhotos } from "../utils/trip_utils";

export const Profile = () => {
  const currentUserId = localStorage.getItem("user_id");
  const { user_id } = useParams();
  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);

  const [trips, setTrips] = useState(undefined);
  const [profile, setProfile] = useState(undefined);
  const [profileFollowers, setProfileFollowers] = useState({
    executed: false,
    response: undefined,
  });
  const [profileFollowing, setProfileFollowing] = useState({
    executed: false,
    response: undefined,
  });
  const [currentUserFollowers, setCurrentUserFollowers] = useState({
    executed: false,
    response: undefined,
  });
  const [currentUserFollowing, setCurrentUserFollowing] = useState({
    executed: false,
    response: undefined,
  });
  const [profileFollowerCount, setProfileFollowerCount] = useState(undefined);
  const [profileFollowingCount, setProfileFollowingCount] = useState(undefined);

  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [loading, setLoading] = useState({});
  const [suggestedProfiles, setSuggestedProfiles] = useState({
    executed: false,
    response: undefined,
  });
  const [ownProfile, setOwnProfile] = useState(undefined);
  const [dialogItems, setDialogItems] = useState(undefined);
  const [avatarFileStr, setAvatarFileStr] = useState(undefined);
  const [avatarFileExtension, setAvatarFileExtension] = useState(undefined);
  const [alert, setAlert] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [cropPhotoDialogOpen, setCropPhotoDialogOpen] = useState(false);
  const [tripsView, setTripsView] = useState(
    localStorage.getItem("profile_view") || "map"
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("last_modified_at");
  const [tripsCoverPhotos, setTripCoverPhotos] = useState(undefined);

  const hasSession =
    localStorage.getItem("first_name") &&
    localStorage.getItem("last_name") &&
    localStorage.getItem("user_id") &&
    localStorage.getItem("email")
      ? true
      : false;

  useEffect(() => {
    if (user_id) {
      // get user profile
      // get their avatar
      // get all their trips
      fetchTripOwner({ userId: user_id }).then((owner) => {
        if (owner) {
          fetchTripsForUser({ userId: user_id }).then((trips) => {
            getFile({ id: user_id, bucket: "avatars" }).then((file) => {
              if (file) {
                const profileAddedInfo = { ...owner };
                // TO DO
                // might be erroring, investigate further
                if (file.message === "File not found") {
                  profileAddedInfo.avatarFileStr = file.message;
                  setAvatarFileStr(undefined);
                  setAvatarFileExtension(undefined);
                } else if (!file.error) {
                  setAvatarFileStr(convertToBase64(file));
                  setAvatarFileExtension(file.extension);
                }
                // only show public trips on profile page
                // TO DO create end point for retrieving public trips only
                // rather than filter on client side the way it is currently
                const publicTrips = trips.filter((trip) => trip.is_public);
                setTrips(publicTrips);
                setProfile(owner);

                if (publicTrips && publicTrips.length) {
                  let tripIds = publicTrips.map(({ id }) => {
                    return {
                      id,
                    };
                  });
                  retrieveCoverPhotos({ tripIds }).then((coverPhotos) => {
                    setTripCoverPhotos(coverPhotos);
                  });
                }
              } else {
                setAvatarFileStr(undefined);
                setAvatarFileExtension(undefined);
              }
            });
          });
        }
      });
    }
  }, [user_id]);

  // when profile changes we want to fetch all information pertaining to followers / following
  // including avatar
  useEffect(() => {
    if (profile && profile.id) {
      // get follower / following count first since that what we want to present initially
      getFollowerCount({ id: profile.id }).then((response) => {
        if (response >= 0) {
          setProfileFollowerCount(response);
        }
      });
      getFollowingCount({ id: profile.id }).then((response) => {
        if (response >= 0) {
          setProfileFollowingCount(response);
        }
      });

      getFollowers({ id: profile.id }).then((response) => {
        if (response) {
          setProfileFollowers({ executed: true, response: response || [] });
        }
      });
      getFollowing({ id: profile.id }).then((response) => {
        if (response) {
          setProfileFollowing({ executed: true, response: response || [] });
        }
      });
    }
  }, [profile, trips]);

  useEffect(() => {
    if (currentUserId && profile) {
      setOwnProfile(currentUserId === profile.id);
      getFollowers({ id: currentUserId }).then((response) => {
        if (response) {
          setCurrentUserFollowers({ executed: true, response: response || [] });
        }
      });
      getFollowing({ id: currentUserId }).then((response) => {
        if (response) {
          setCurrentUserFollowing({ executed: true, response: response || [] });
        }
      });
    }
  }, [currentUserId, profile]);

  useEffect(() => {
    if (profileFollowing.response && trips && profile && ownProfile) {
      // collaborators now includes owners
      async function getCollaborators() {
        let tripsCollaborators = await Promise.all(
          trips.map(async (trip) => {
            return await fetchCollaboratorsForTrip({ tripId: trip.id });
          })
        );
        const flattenedCollaborators = tripsCollaborators.flat();
        const filteredCollaborators = flattenedCollaborators.filter(
          //filter out duplicate ids, unverified users and self
          (c, i, a) =>
            a.findIndex((c2) => c2.userId === c.userId) === i &&
            c.userId &&
            c.userId !== currentUserId //filter self
        );

        let unfollowedCollaborators = filteredCollaborators;
        if (profileFollowing.response.length) {
          unfollowedCollaborators = unfollowedCollaborators.filter(
            (collaborator) => {
              return profileFollowing.response.every((followee) => {
                return collaborator.userId !== followee.id;
              });
            }
          );
        }
        // TO DO expand notion of suggestedFollowers to followers, following, etc.
        if (unfollowedCollaborators && unfollowedCollaborators.length) {
          getSuggestedProfiles({ profiles: unfollowedCollaborators }).then(
            (response) => {
              if (response) {
                setSuggestedProfiles({ executed: true, response });
              }
            }
          );
        } else {
          setSuggestedProfiles({ executed: true, response: [] });
        }
      }

      if (trips && profileFollowing) {
        getCollaborators();
      }
    }
  }, [profileFollowing, trips, profile, ownProfile, currentUserId]);

  // TO DO factor these out
  const TopLevelFollowHandler = ({ profileId }) => {
    if (profileId && currentUserId) {
      followUser({ profileId, currentUserId }).then((response) => {
        if (response) {
          // increment profileFollowerCount
          setProfileFollowerCount(profileFollowerCount + 1);
          // increment profileFollowers by currentUser
          const updatedFollowers = [
            ...profileFollowers.response,
            // adding current userId
            {
              id: currentUserId,
              email: localStorage.getItem("email"),
              file: localStorage.getItem("current_user_avatar_url")
                ? {
                    str: localStorage.getItem("current_user_avatar_url"),
                  }
                : { message: "File not found" },
              first_name: localStorage.getItem("first_name"),
              last_name: localStorage.getItem("last_name"),
            },
          ];
          setProfileFollowers({ executed: true, response: updatedFollowers });
          const updatedLoading = loading && delete loading[profileId];
          setLoading(updatedLoading);
        }
      });
    }
  };

  const TopLevelUnfollowHandler = ({ profileId }) => {
    if (profileId && currentUserId) {
      unfollowUser({ profileId, currentUserId }).then(async (response) => {
        if (response) {
          // decrement profileFollowerCount
          setProfileFollowerCount(profileFollowerCount - 1);
          // remove currentUser from profileFollowers
          const profileFollowersFiltered = profileFollowers.response.filter(
            (f) => {
              return f.id !== currentUserId;
            }
          );
          setProfileFollowers({
            executed: true,
            response: profileFollowersFiltered,
          });
          const updatedLoading = loading && delete loading[profileId];
          setLoading(updatedLoading);
        }
      });
    }
  };

  const DialogFollowHandler = ({ id, email, file, first_name, last_name }) => {
    if (id && currentUserId) {
      followUser({ profileId: id, currentUserId }).then((response) => {
        if (response) {
          // if viewing own profile, increment counter
          // add profile that was just followed to profileFollowing
          if (ownProfile) {
            setProfileFollowingCount(profileFollowingCount + 1);
            const updatedFollowing = [
              ...profileFollowing.response,
              {
                id,
                email,
                file,
                first_name,
                last_name,
              },
            ];
            setProfileFollowing({ executed: true, response: updatedFollowing });
          }
          // update currentUserFollowing to include profile that was just followed
          const updatedCurrentUserFollowing = [
            ...currentUserFollowing.response,
            {
              id,
              email,
              file,
              first_name,
              last_name,
            },
          ];
          setCurrentUserFollowing({
            executed: true,
            response: updatedCurrentUserFollowing,
          });
          const updatedLoading = loading && delete loading[id];
          setLoading(updatedLoading);
        }
      });
    }
  };

  const DialogUnfollowHandler = ({ id }) => {
    if (id && currentUserId) {
      unfollowUser({ profileId: id, currentUserId }).then((response) => {
        if (response) {
          if (ownProfile) {
            setProfileFollowingCount(profileFollowingCount - 1);
            const profileFollowingFiltered = profileFollowing.response.filter(
              (f) => f.id !== id
            );
            setProfileFollowing({
              executed: true,
              response: profileFollowingFiltered,
            });
          }
          // update currentUserFollowing to remove profile that was just unfollowed
          const currentUserFollowingFiltered =
            currentUserFollowing.response.filter((f) => f.id !== id);
          setCurrentUserFollowing({
            executed: true,
            response: currentUserFollowingFiltered,
          });
          const updatedLoading = loading && delete loading[id];
          setLoading(updatedLoading);
        }
      });
    }
  };

  const DialogRemoveFollowerHandler = ({ id }) => {
    if (id && currentUserId) {
      removeFollower({ profileId: id, currentUserId }).then((response) => {
        if (response) {
          // this is always going to be ownProfile
          setProfileFollowerCount(profileFollowerCount - 1);
          const profileFollowersFiltered = profileFollowers.response.filter(
            (f) => f.id !== id
          );
          setProfileFollowers({
            executed: true,
            response: profileFollowersFiltered,
          });
          const updatedLoading = loading && delete loading[id];
          setLoading(updatedLoading);
        }
      });
    }
  };

  const columnsForTable = allTripColumns.filter((column) =>
    ["title", "location", "actions"].includes(column.value)
  );

  const UploadHandler = ({ file }) => {
    const uploadFailureTimeout = setTimeout(() => {
      setAlert({
        severity: "error",
        message: "Something went wrong. Try again.",
      });
      setLoading(false);
    }, 30000);
    const id = currentUserId ? currentUserId : null;
    cropPhotoDialogOpen && setCropPhotoDialogOpen(false);
    uploadFile({
      file,
      id,
      bucket: "avatars",
    }).then((response) => {
      if (response) {
        if (response.status === 500) {
          setAlert({
            severity: "error",
            message: response.message,
          });
        } else if (response.status === 200) {
          setAvatarFileExtension(response.extension);
          // additional logic for converting avatar to smaller sized file
          // so it can be stored in local storage, to be used across pages
          const base64Str = convertToBase64(response);
          resizeBase64Img(base64Str, 250, 250).then((resizedBase64Str) => {
            setAvatarFileStr(resizedBase64Str);
            setLocalStorageItem({
              key: "current_user_avatar_url",
              value: resizedBase64Str,
            });
          });
        }
        setLoading(false);
        clearTimeout(uploadFailureTimeout);
      }
    });
  };

  const SelectFileHandler = ({ file }) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        setCropPhotoDialogOpen(true);
        setLoading(false);
        setSelectedFile({ name: file.name, type: file.type });
      });
      reader.readAsDataURL(file);
    }
  };

  return trips &&
    profile &&
    // make dependent on count to speed up load times
    typeof profileFollowerCount !== "undefined" &&
    typeof setProfileFollowingCount !== "undefined" ? (
    // TO DO use PublicPageWrapper component
    <>
      <Grid item xs={12}>
        {/* crazy */}
        <ProfileSummary
          profile={profile}
          hasSession={hasSession}
          profileFollowerCount={profileFollowerCount}
          profileFollowingCount={profileFollowingCount}
          profileFollowers={profileFollowers}
          profileFollowing={profileFollowing}
          currentUserFollowers={currentUserFollowers}
          currentUserFollowing={currentUserFollowing}
          topLevelFollowHandler={TopLevelFollowHandler}
          topLevelUnfollowHandler={TopLevelUnfollowHandler}
          dialogFollowHandler={DialogFollowHandler}
          dialogUnfollowHandler={DialogUnfollowHandler}
          dialogRemoveFollowerHandler={DialogRemoveFollowerHandler}
          expanded={accordionExpanded}
          setExpanded={setAccordionExpanded}
          loading={loading}
          setLoading={setLoading}
          suggestedProfiles={suggestedProfiles}
          dialogItems={dialogItems}
          setDialogItems={setDialogItems}
          setProfileFollowers={setProfileFollowers}
          setProfileFollowing={setProfileFollowing}
          alert={alert}
          setAlert={setAlert}
          selectFileHandler={SelectFileHandler}
          avatarFileStr={avatarFileStr}
          setAvatarFileStr={setAvatarFileStr}
          setAvatarFileExtension={setAvatarFileExtension}
        />
      </Grid>

      <Grid item xs={12} mt={2}>
        {trips.length ? (
          <ToggleViewBar
            items={[
              {
                key: "map",
                value: "map",
                icon: <MapIcon />,
                tooltip: TripsMapTooltip,
              },
              {
                key: "grid",
                value: "grid",
                icon: <ViewModuleIcon />,
                tooltip: TripsGridTooltip,
              },
              {
                key: "table",
                value: "table",
                icon: <TableRowsIcon />,
                tooltip: TripsTableTooltip,
              },
            ]}
            view={tripsView}
            setView={setTripsView}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            title={<Typography variant="h6">User's Public Trips</Typography>}
            callee="profile"
          />
        ) : (
          ""
        )}

        {trips.length ? (
          tripsView === "table" ? (
            <Grid container mt={2}>
              <TripsTable
                trips={trips}
                actions={["edit", "share", "copy"]}
                columns={columnsForTable}
                callee="profile"
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
            </Grid>
          ) : tripsView === "grid" ? (
            <Grid container mt={2}>
              <GridView
                trips={trips}
                callee="profile"
                actions={["share", "copy"]}
                setTrips={setTrips}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                tripsCoverPhotos={tripsCoverPhotos}
                setTripCoverPhotos={setTripCoverPhotos}
              />
            </Grid>
          ) : (
            <Grid
              container
              mt={2}
              sx={{
                minHeight: screenIsSmall ? "400px" : "600px",
                paddingBottom: "70px",
              }}
            >
              <MapView
                places={trips}
                callee="profile"
                styleOverrides={{
                  height: screenIsSmall ? "400px" : "700px",
                }}
              />
            </Grid>
          )
        ) : (
          <EmptyState
            children={
              <>
                <Typography
                  component="div"
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontWeight: "500",
                    fontSize: "0.875rem",
                  }}
                >
                  {UserHasNoPublicTripsExplanation}
                </Typography>
              </>
            }
          />
        )}
      </Grid>

      {cropPhotoDialogOpen && imgSrc ? (
        <CropPhotoDialog
          imgSrc={imgSrc}
          open={cropPhotoDialogOpen}
          handleClose={() => setCropPhotoDialogOpen(false)}
          selectedFile={selectedFile}
          uploadHandler={UploadHandler}
          loading={loading}
          setLoading={setLoading}
          circularCrop={true}
          aspectRatio={1}
        />
      ) : (
        ""
      )}
    </>
  ) : (
    <Loader />
  );
};
