export const allowedTripFileInfo = {
  fileTypes: ["image/png", "image/jpeg"],
  fileExtensions: ["png", "jpeg"],
  fileSize: {
    kb: 5 * 1024 * 1024,
    readable: "5MB",
  },
};

export const allowedEventFileInfo = {
  fileTypes: ["image/png", "image/jpeg", "application/pdf"],
  fileExtensions: ["png", "jpeg", "pdf"],
  fileSize: {
    kb: 5 * 1024 * 1024,
    readable: "5MB",
  },
};
// increasing size of avatar since local storage limitation has been solved
export const allowedAvatarFileInfo = {
  fileTypes: ["image/png", "image/jpeg"],
  fileExtensions: ["png", "jpeg"],
  fileSize: {
    kb: 5 * 1024 * 1024,
    readable: "5MB",
  },
};

export const TripAddCollaboratorsExplanation = "Add travel partners as editors";
export const TripVisibilityToggleExplanation =
  "Enable URL for anyone on the internet to view trip";
export const PrivateTripTooltip =
  "Private trips can only be accessed by editors";
export const PublicTripTooltip =
  "Public trips can be viewed by anyone on the internet at the shared URL";
export const FavoriteEventTooltip = "Favorite event";
export const FavoriteEventExplanation = "Click on events to favorite them";
export const ShowFavoriteEventsTooltip = "Show favorite events only";
export const EventTitleRequiredTooltip = "Title is required";
export const NavigateToShareTripTooltip = "Go to share trip URL";
export const CopyShareTripTooltip = "Copy share trip URL";
export const ExploreShareTripLocationTooltip =
  "Explore other trips from this location";
export const RequiredFieldsTooltip = "Add all required fields";
export const CopyTripStep1Explantion = "Name your trip and choose a start date";
export const CopyTripStep2Explantion = "Select events";
export const CopyTripIconButtonTooltip = "Make a copy of this trip!";
export const CopyTripIconButtonTooltipSignedOut =
  "Copy trip. You must be logged in to perform this action.";
export const CopyTripButtonTooltip = "At least one event must be selected";
export const CopyTripEndDateExplanation =
  "End date can be changed once the trip is created.";
export const CopyTripAttachmentsExplanation =
  "Attachments from the original trip will not be copied.";
export const CopyProfileTooltip = "Copy user's profile URL";
export const CopyURLConfirmMessage = "URL copied to clipboard!";
export const EditTripButtonTooltip = "Edit trip";
export const EventDialogStep1Explanation = "Add title and dates";
export const EventDialogStep2Explanation =
  "Add location, type, description and attachment";
export const EmptyResultsExplanation = "Results will appear here";
export const NoResultsExplanation = "No results";
export const UserHasNoPublicTripsExplanation =
  "User does not have any public trips!";
export const MapHasNoPlacesExplanation = "Can't render map without places";
export const EmojiPickerButtonTooltip = "Add emojis";

export const EditCalendarOptions = [
  { value: "timeGridDay", label: "Day" },
  { value: "timeGridFourDay", label: "4 Day" },
  { value: "timeGridWeek", label: "Week" },
  { value: "dayGridMonth", label: "Month" },
  { value: "listMonth", label: "List" },
];
export const DeleteTripButtonTooltip = "Delete trip";

export const CustomCalendarViews = {
  timeGridFourDay: {
    type: "timeGrid",
    duration: { days: 4 },
    buttonText: "4 day",
  },
};
export const TripCalendarTooltip = "View trip as calendar";
export const TripMapTooltip = "View trip as map";
export const TripMapDisabledTooltip = "Cannot view trip as map without places";
export const TripsTableTooltip = "View trips as table";
export const TripsGridTooltip = "View trips as grid";
export const FileNotFoundMessage = "File not found";
export const TripsMapTooltip = "View trips on map";
export const TripNotAllEventsMessage = "Not all events can be shown";
export const TripExpandSidebarTooltip = "Expand sidebar";
export const TripCollapseSidebarTooltip = "Collapse sidebar";
export const ShowMoreTooltip = "Show more";
export const ShowLessTooltip = "Show less";
export const dayInMs = 86400000;

export const GoogleMapsLibraries = ["places", "maps"];

// https://news.ycombinator.com/item?id=21133300
export const averageCityRadiusInMeters = 150000;

export const eventBackgroundColorFallback = "#830902";

export const allTripColumns = [
  {
    value: "title",
    label: "Title",
    id: "title",
    sortable: true,
    align: "center",
  },
  {
    value: "permission",
    label: "Permission",
    id: "collaborator", // doesn't match
    sortable: true,
    align: "center",
  },
  {
    value: "public views",
    label: "Lifetime views",
    id: "public_views",
    sortable: true,
    align: "center",
  },
  {
    value: "last modified",
    label: "Last Modified",
    id: "last_modified_at",
    sortable: true,
    sx: { display: { xs: "none", md: "table-cell" } },
    align: "center",
  },
  {
    value: "dates",
    label: "Start date - End date",
    id: "start_date",
    sortable: true,
    sx: { display: { xs: "none", md: "table-cell" } },
    align: "center",
  },
  {
    value: "location",
    label: "Location",
    id: "location",
    sortable: true,
    align: "center",
  },
  {
    value: "actions",
    label: "Actions",
    id: "actions",
    sortable: false,
    align: "center",
  },
];
