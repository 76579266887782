import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ShareIcon from "@mui/icons-material/Share";
import { Theme } from "../Theme";
import IosShareIcon from "@mui/icons-material/IosShare";
import { CopyToClipboardButton } from "./Buttons";
import {
  CopyURLConfirmMessage,
  CopyShareTripTooltip,
  FavoriteEventExplanation,
  NavigateToShareTripTooltip,
} from "../constants";
import { AddEventButton, FavoritesToggle } from "./Buttons";

export const CalendarAppBar = ({
  calendarTitle,
  setCalendarTitle,
  calendarView,
  setCalendarView,
  setEventClicked,
  setDialogOpen,
  currentDay,
  setCurrentDay,
  startDate,
  endDate,
  calendarApi,
  editable,
  calendarViewOptions,
  isPublic,
  showFavorites,
  setShowFavorites,
  events,
  currentUserIsEditor,
}) => {
  const { trip_id } = useParams();
  const navigate = useNavigate();

  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);

  useEffect(() => {
    if (calendarApi) {
      calendarApi.changeView(calendarView, {
        start: startDate,
        end: endDate,
      });
    }
  }, [currentDay, calendarView, startDate, endDate, calendarApi]);

  // listen for changeView since was previously causing race condition
  // when they were all in one useEffect
  useEffect(() => {
    calendarApi?.currentData &&
      setCalendarTitle(calendarApi.currentData.viewTitle);
  }, [calendarApi?.changeView, calendarApi?.currentData, setCalendarTitle]);

  if (!calendarApi) return null;

  return (
    <Card
      sx={{
        mt: 1,
        backgroundColor: `${Theme.palette.grey["100"]}`,
        borderRadius: "4px 4px 0px 0px",
      }}
      elevation={0}
    >
      <Grid
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        container
        sx={{
          padding: 2,
        }}
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>{calendarTitle}</b>
          </Typography>
        </Grid>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          sx={{ mt: 1, width: "100%" }}
          alignItems="center"
        >
          {editable ? (
            <AddEventButton
              screenIsSmall={screenIsSmall}
              setEventClicked={setEventClicked}
              setDialogOpen={setDialogOpen}
              currentDay={currentDay}
              startDate={startDate}
            />
          ) : (
            ""
          )}
          <Grid item>
            <Tooltip title={"Previous"}>
              <span>
                <IconButton
                  aria-label="navigate-before"
                  onClick={() => {
                    calendarApi.prev();
                    setCurrentDay(
                      calendarApi.currentData.dateProfile.currentDate
                    );
                  }}
                  disabled={
                    calendarApi.currentData.dateProfile.currentRange.start <=
                    startDate
                      ? true
                      : false
                  }
                  size="small"
                >
                  <NavigateBeforeIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={"Next"}>
              <span>
                <IconButton
                  aria-label="navigate-next"
                  onClick={() => {
                    calendarApi.next();
                    setCurrentDay(
                      calendarApi.currentData.dateProfile.currentDate
                    );
                  }}
                  disabled={
                    calendarApi.currentData.dateProfile.currentRange.end >
                    endDate // make > not >= due to edge case
                      ? true
                      : false
                  }
                  size="small"
                >
                  <NavigateNextIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          {calendarViewOptions ? (
            <FormControl>
              <InputLabel id="demo-simple-select-label">View</InputLabel>
              <Select
                labelId="text-field-calendar-view-label"
                value={calendarView}
                label="Type"
                onChange={(e) => {
                  setCalendarView(e.target.value);
                  localStorage.setItem(
                    `${trip_id}`,
                    JSON.stringify({ calendarView: e.target.value })
                  );
                }}
                slotProps={{
                  textField: { label: "Type" },
                }}
                id={"text-field-calendar-view"}
                size="small"
                defaultValue=""
              >
                {calendarViewOptions.map(({ value, label }) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            ""
          )}

          {currentUserIsEditor ? (
            <Typography
              component="div"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                fontWeight: "500",
                fontSize: "0.875rem",
              }}
            >
              {FavoriteEventExplanation}
            </Typography>
          ) : (
            ""
          )}

          {!editable && events ? (
            <FavoritesToggle
              visibility={events.favorites.length > 0 ? "visible" : "hidden"}
              showFavorites={showFavorites}
              setShowFavorites={setShowFavorites}
            />
          ) : (
            ""
          )}

          {isPublic ? (
            <Grid item>
              <Tooltip title={NavigateToShareTripTooltip}>
                <IconButton onClick={() => navigate(`/trip/share/${trip_id}`)}>
                  <ShareIcon />
                </IconButton>
              </Tooltip>
              <CopyToClipboardButton
                url={`${window.location.origin}/trip/share/${trip_id}`}
                icon={<IosShareIcon />}
                confirmMessage={CopyURLConfirmMessage}
                tooltipTitle={CopyShareTripTooltip}
              />
            </Grid>
          ) : (
            // TO DO add user actions menu here
            ""
          )}
        </Stack>
      </Grid>
    </Card>
  );
};
