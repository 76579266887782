import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/localizedFormat"));
dayjs.extend(require("dayjs/plugin/timezone"));

export const addDays = (date, n) => {
  const oneDayInMs = 1000 * 60 * 60 * 24;
  return new Date(Date.parse(date) + n * oneDayInMs);
};
export const subtractDays = (date, n) => {
  const oneDayInMs = 1000 * 60 * 60 * 24;
  return new Date(Date.parse(date) - n * oneDayInMs);
};

export const addHours = (date, n) => {
  const oneHourinMs = 1000 * 60 * 60;
  return new Date(Date.parse(date) + n * oneHourinMs);
};
export const addMinutes = (date, n) => {
  const oneMinuteinMs = 1000 * 60;
  return new Date(Date.parse(date) + n * oneMinuteinMs);
};
export const substractHours = (date, n) => {
  const oneHourinMs = 1000 * 60 * 60;
  return new Date(Date.parse(date) - n * oneHourinMs);
};

export const diffDays = (date1, date2) => {
  const diffTime = Math.abs(Date.parse(date2) - Date.parse(date1));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const utcDate = (date) => {
  if (date) {
    return dayjs.utc(date);
  }
  return undefined;
};
export const localDate = (date) => {
  if (date) {
    return dayjs(date);
  }
  return undefined;
};

export const getDay = (date) => {
  if (date) {
    return dayjs(date).day();
  }
  return undefined;
};

export const getHour = (date) => {
  if (date) {
    return dayjs(date).hour();
  }
  return undefined;
};

export const isSameMonth = (date1, date2) => {
  const month1 = new Date(date1).getMonth();
  const year1 = new Date(date1).getYear();
  const month2 = new Date(date2).getMonth();
  const year2 = new Date(date2).getYear();

  return month1 === month2 && year1 === year2;
};

export const diffEvent = (date1, date2) => {
  const diffTime = Math.abs(Date.parse(date2) - Date.parse(date1));
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffTime;
};

// TO DO
// combine addTime functions, i.e. addTimeinMs, addDays, addHours above
export const addTimeinMs = (date, n) => {
  return new Date(Date.parse(date) + n);
};

// https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const getTime = (date) => {
  if (date) {
    return {
      hour: dayjs(date).hour(),
      minute: dayjs(date).minute(),
    };
  }
  return undefined;
};
