import { Button, IconButton, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { utcDate, addHours } from "../../utils/date_utils";

// TO DO figure out why there's discrepency between initial time value of this button
// local 4pm, prod 12pm -- works for now so OK
export const AddEventButton = ({
  screenIsSmall,
  setEventClicked,
  setDialogOpen,
  currentDay,
  startDate,
}) => {
  const handleClick = () => {
    // hack for initializing dates when
    // create event button clicked
    setEventClicked({
      start: currentDay
        ? utcDate(addHours(currentDay, 12))
        : utcDate(addHours(startDate, 12)),
      end: currentDay
        ? utcDate(addHours(currentDay, 13))
        : utcDate(addHours(startDate, 13)),
      callee: "createEventButton",
    });

    setDialogOpen(true);
  };

  if (screenIsSmall) {
    return (
      <Tooltip title="Add event">
        <IconButton
          aria-label="Add event"
          variant="contained"
          onClick={handleClick}
        >
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Button onClick={handleClick} variant="contained" size="small">
        Add Event
      </Button>
    );
  }
};
