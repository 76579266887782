import { useContext } from "react";
import {
  DialogTitle,
  Grid,
  ToggleButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { CloseModal } from "./";
import { AppContext } from "../../contexts";
import {
  fetchEventsForTrip,
  toggleFavoriteEvent,
} from "../../utils/event_utils";
import { useParams } from "react-router-dom";
import { CollaboratorChip } from "../";
import { FavoriteEventTooltip } from "../../constants";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

export const EventDialogTitle = ({
  newEvent,
  loading,
  handleClose,
  creator,
  eventClicked,
  favorite,
  setFavorite,
}) => {
  const theme = useTheme();
  const { trip_id } = useParams();
  const { setEvents } = useContext(AppContext);
  return (
    <Grid
      container
      sx={{
        alignItems: "center",
      }}
    >
      <Grid item>
        <DialogTitle>{newEvent ? "Create Event" : "Event"}</DialogTitle>
      </Grid>
      {!newEvent ? (
        <Tooltip title={FavoriteEventTooltip}>
          <span>
            <ToggleButton
              aria-label="favorite"
              onClick={() => {
                toggleFavoriteEvent({
                  id: eventClicked.id,
                  isFavorite: !favorite,
                }).then((result) => {
                  if (result) {
                    fetchEventsForTrip({
                      tripId: trip_id,
                      callee: "edit",
                    }).then((events) => {
                      setEvents(events);
                    });
                    setFavorite(!favorite);
                  }
                });
              }}
              disabled={loading ? true : false}
              selected={favorite}
              size="small"
              label="favorite"
              sx={{ marginLeft: "10px" }}
              value={favorite}
            >
              {favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </ToggleButton>
          </span>
        </Tooltip>
      ) : (
        ""
      )}

      {handleClose ? (
        <CloseModal
          handleClose={() => {
            handleClose();
          }}
          loading={loading}
        />
      ) : (
        ""
      )}

      {creator ? (
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.grey[700], paddingLeft: "24px" }}
          >
            <CollaboratorChip
              collaborator={creator}
              index={0}
              handleDeleteCollaborator={null}
              key="event-creator-chip"
              tooltipTitle="Event creator"
              size="small"
            />
          </Typography>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};
