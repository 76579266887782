import { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { getLatLng } from "../utils/maps_utils";
import { averageCityRadiusInMeters } from "../constants";
import { APIProvider } from "@vis.gl/react-google-maps";
import { GoogleMapsLibraries } from "../constants";
const autocompleteService = { current: null };

export const GoogleMapsAutocompleteWrapper = ({
  location,
  setLocation,
  setLat,
  setLng,
  required,
  loading,
  tripLatLng,
  label,
}) => {
  return (
    // per comment here, can use APIProvider with libraries places to get places api
    // https://github.com/ErrorPro/react-google-autocomplete/issues/89
    <APIProvider
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={GoogleMapsLibraries}
      version="beta"
    >
      <GoogleMapsAutocomplete
        location={location}
        setLocation={setLocation}
        setLat={setLat}
        setLng={setLng}
        required={required}
        loading={loading}
        tripLatLng={tripLatLng}
        label={label}
      />
    </APIProvider>
  );
};

// https://mui.com/material-ui/react-autocomplete/#google-maps-place
export const GoogleMapsAutocomplete = ({
  location,
  setLocation,
  setLat,
  setLng,
  required,
  loading,
  tripLatLng,
  label,
}) => {
  const [value, setValue] = useState(
    location ? { description: location } : null //must default to null, undefined causes it to break
  );
  const [inputValue, setInputValue] = useState(location ? location : "");
  const [options, setOptions] = useState([]);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        try {
          autocompleteService.current.getPlacePredictions(request, callback);
        } catch (e) {
          // console.log({ e });
        }
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;
    if (
      !autocompleteService.current &&
      window.google &&
      window.google.maps.places
    ) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    try {
      let requestOptions = {
        input: inputValue,
      };
      if (tripLatLng) {
        // This github comment helped with location biasing
        // https://github.com/wellyshen/use-places-autocomplete/issues/904#issuecomment-1555130404
        // location biasing docs
        // https://developers.google.com/maps/documentation/places/web-service/autocomplete#locationbias
        // TO DO migrate to locationBias but unclear on object properties
        requestOptions.location = new window.google.maps.LatLng(
          tripLatLng.lat,
          tripLatLng.lng
        );
        requestOptions.radius = averageCityRadiusInMeters;
      }
      fetch(requestOptions, (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });

      return () => {
        active = false;
      };
    } catch (e) {
      // console.log({ e });
    }
  }, [value, inputValue, fetch]);

  useEffect(() => {
    if (value) {
      if (value.place_id && value.description) {
        getLatLng({
          placedId: value.place_id,
        }).then((response) => {
          if (response) {
            const lat = response.results[0].geometry.location.lat;
            const lng = response.results[0].geometry.location.lng;

            if (lat) setLat(lat);
            if (lng) setLng(lng);

            setLocation(value.description);
          }
        });
      }
    } else {
      setLocation("");
      setLat("");
      setLng("");
    }
  }, [value, setLat, setLng, setLocation]);

  useEffect(() => {
    // if there's a location and value is null
    // set as below
    if (location && location.length && value === null) {
      setValue({ description: location });
    }
  }, [location, value]);

  return (
    <Autocomplete
      id="text-field-location"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      // https://github.com/mui/material-ui/issues/36548#issuecomment-1674991559
      inputValue={inputValue}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue ? newValue : ""); //set to value or empty string, cannot be null again
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      disabled={loading ? true : false}
      renderInput={(params) => (
        <TextField
          {...params}
          // required for trip not event
          required={required ? true : false}
          label={label}
          id="text-field-location"
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const matches =
          (option.structured_formatting &&
            option.structured_formatting.main_text_matched_substrings) ||
          [];

        const parts = parse(
          option.structured_formatting &&
            option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        const { key, ...rest } = props;

        return (
          <li key={key} {...rest}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting &&
                    option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
