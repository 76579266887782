import { AppContext } from "../contexts";
import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  EditTripCalendar,
  Loader,
  MapView,
  TripShareDetail,
  ToggleViewBar,
  TripDetail,
} from "./";
import {
  TripCalendarTooltip,
  TripNotAllEventsMessage,
  TripMapDisabledTooltip,
  TripMapTooltip,
  TripExpandSidebarTooltip,
  TripCollapseSidebarTooltip,
} from "../constants";
import { fetchEventsForTrip } from "../utils/event_utils";
import { validatePlaces } from "../utils/maps_utils";

export const Trip = () => {
  const { trips, events } = useContext(AppContext);
  const { trip_id } = useParams();

  const theme = useTheme();
  const screenIsMediumOrUp = useMediaQuery(theme.breakpoints.up("md"));
  const [expanded, setExpanded] = useState(
    screenIsMediumOrUp ? "overview" : ""
  );
  const [tripView, setTripView] = useState(
    localStorage.getItem(`trip_${trip_id}_view`) || "calendar"
  );
  const [places, setPlaces] = useState(null);
  const [validPlaces, setValidPlaces] = useState(null);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const collapsedWidth = "45px";
  const expandedWidth = "33%";

  // on resize ensure expanded on desktop, collapsed on mobile
  useEffect(() => {
    if (screenIsMediumOrUp) {
      setSidebarCollapsed(false);
    } else {
      setExpanded(true);
      setSidebarCollapsed(false);
    }
  }, [screenIsMediumOrUp]);

  useEffect(() => {
    if (trip_id) {
      fetchEventsForTrip({ tripId: trip_id, callee: "edit" }).then((events) => {
        setPlaces(events);
        setValidPlaces(validatePlaces(events));
      });
    }
  }, [trip_id, setPlaces, tripView, events]);

  const handleDrawer = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return trips ? (
    <Grid container spacing={2}>
      <Box
        sx={{
          textAlign: "left",
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: {
            xs: "100%",
            md: sidebarCollapsed ? collapsedWidth : `calc(${expandedWidth})`,
          },
        }}
        pt={2}
        pl={2}
      >
        {screenIsMediumOrUp ? (
          <Stack direction="row" spacing={1} justifyContent="flex-start" mb={1}>
            <Tooltip
              title={
                sidebarCollapsed
                  ? TripExpandSidebarTooltip
                  : TripCollapseSidebarTooltip
              }
            >
              <IconButton onClick={handleDrawer}>
                {sidebarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          ""
        )}
        <TripDetail
          expanded={expanded}
          setExpanded={setExpanded}
          sidebarCollapsed={sidebarCollapsed}
        />
        <TripShareDetail
          expanded={expanded}
          setExpanded={setExpanded}
          sidebarCollapsed={sidebarCollapsed}
        />
      </Box>
      <Box
        sx={{
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: {
            xs: "100%",
            md: sidebarCollapsed
              ? `calc((100% - ${collapsedWidth}))`
              : `calc((100% - ${expandedWidth}))`,
          },
        }}
        pt={2}
        pl={2}
      >
        <ToggleViewBar
          items={[
            {
              key: "calendar",
              value: "calendar",
              icon: <CalendarMonthIcon />,
              tooltip: TripCalendarTooltip,
            },
            {
              key: "map",
              value: "map",
              icon: <MapIcon />,
              tooltip: validPlaces?.length
                ? TripMapTooltip
                : TripMapDisabledTooltip,
              disabled: validPlaces?.length ? false : true,
            },
          ]}
          view={tripView}
          setView={setTripView}
          callee={`trip_${trip_id}`}
          title={
            tripView === "map" && validPlaces?.length !== places?.length ? (
              <Alert severity="info" sx={{ width: "100%", overflow: "scroll" }}>
                {TripNotAllEventsMessage}
              </Alert>
            ) : (
              <span />
            )
          }
        />
        {tripView === "calendar" ? (
          <EditTripCalendar sidebarCollapsed={sidebarCollapsed} />
        ) : (
          <Grid container mt={2}>
            <MapView
              places={places}
              callee="trip"
              styleOverrides={{
                height: screenIsMediumOrUp ? "700px" : "400px",
              }}
            />
          </Grid>
        )}
      </Box>
    </Grid>
  ) : (
    <Loader />
  );
};
