import { Button } from "@mui/material";
import { saveTripDescription } from "../../utils/trip_utils";

export const SaveTripDescriptionButton = ({
  tripId,
  content,
  handleClose,
  loading,
  setLoading,
}) => {
  return (
    <Button
      onClick={() => {
        setLoading(true);
        saveTripDescription({ tripId, description: content }).then((result) => {
          if (result) {
            setLoading(false);
            handleClose();
          }
        });
      }}
      variant="contained"
      disabled={content && !loading ? false : true}
    >
      Save
    </Button>
  );
};
