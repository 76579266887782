import React, { useContext, useEffect, useState, useCallback } from "react";
import { AppContext } from "../contexts";
import { useParams, useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { Box, useTheme, useMediaQuery, Grid } from "@mui/material";
import { EventDialog } from "./Modals";
import { fetchEventsForTrip, saveEvent } from "../utils/event_utils";
import {
  diffDays,
  addDays,
  utcDate,
  getDay,
  getTime,
  addHours,
  addMinutes,
  subtractDays,
} from "../utils/date_utils";
import { getFile, convertToBase64 } from "../utils/storage_utils";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import { CalendarAppBar } from ".";
import {
  CustomCalendarViews,
  EditCalendarOptions,
  eventBackgroundColorFallback,
} from "../constants";
import {
  handleEventMouseEnter,
  handleEventMouseLeave,
} from "../utils/calendar_utils";

export const EditTripCalendar = ({ sidebarCollapsed }) => {
  const {
    places,
    // setPlaces,
    startDate,
    endDate,
    events,
    setEvents,
    userId,
    trips,
    setTripTitle,
    setTripLocation,
    setStartDate,
    setEndDate,
    setEventFileStr,
    setEventFileExtension,
    setShowSnackbar,
  } = useContext(AppContext);

  const { trip_id } = useParams();
  let navigate = useNavigate();
  const [calendarApi, setCalendarApi] = useState(null);
  const calendarRef = useCallback((element) => {
    if (!calendarApi) setCalendarApi(element?.getApi());
  }, []);
  const [calendarContainerHeight, setCalendarContainerHeight] = useState(0);
  const calendarContainerRef = useCallback((element) => {
    setCalendarContainerHeight(element?.clientHeight);
  }, []);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dateClicked, setDateClicked] = useState(undefined);
  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);
  //prevents n drags from created n events
  const [draggableInstantiated, setDraggableInstantiated] = useState(false);
  const [eventClicked, setEventClicked] = useState(undefined);
  const [eventSelected, setEventSelected] = useState(undefined);
  const [tripLengthInDays, setTripLengthInDays] = useState(
    diffDays(endDate, startDate)
  );
  // fallback of empty string was causing issue for new trip without events
  const savedCalendarView =
    localStorage.getItem(trip_id) && JSON.parse(localStorage.getItem(trip_id))
      ? JSON.parse(localStorage.getItem(trip_id)).calendarView
      : null;
  const [calendarView, setCalendarView] = useState(savedCalendarView);
  const [firstDay, setFirstDay] = useState(0);
  const [currentDay, setCurrentDay] = useState(0);
  const [calendarTitle, setCalendarTitle] = useState(undefined);
  const [tripInitialized, setTripInitialized] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleEventClick = ({ event, el }) => {
    if (events.length) {
      const matchingEvent = events.find((item) => {
        return item.id === event._def.publicId;
      });
      setEventClicked(matchingEvent);
    }
  };

  // file logic here before rendering dialog,
  // causes potential latency issue??
  useEffect(() => {
    if (eventClicked && eventClicked.id) {
      getFile({ id: eventClicked.id, bucket: "events" }).then((file) => {
        if (file.message === "File not found") {
          setEventFileStr(file.message);
        } else if (!file.error) {
          setEventFileStr(convertToBase64(file));
          setEventFileExtension(file.extension);
        }
        setDialogOpen(true);
      });
    }
  }, [eventClicked, setEventFileExtension, setEventFileStr]);

  const handleDateClick = (dateInfo) => {
    setDateClicked(dateInfo);
  };

  const handleDialogClose = () => {
    // undo selection just in case...
    calendarApi && calendarApi.unselect();
    // close dialog
    setDialogOpen(false);
    setActiveStep(0);
  };

  // once closed, reinitialize state
  useEffect(() => {
    if (!dialogOpen) {
      setDateClicked(undefined);
      setEventClicked(undefined);
      setEventSelected(undefined);
    }
  }, [dialogOpen]);

  // if dateClicked and dialog not open,
  // open dialog
  useEffect(() => {
    if (dateClicked && !dialogOpen) {
      setDialogOpen(true);
    }
  }, [dateClicked, dialogOpen]);

  const handleEventDropResize = (eventInfo) => {
    const matchingEvent = events.find((event) => {
      return event.id === eventInfo.event._def.publicId;
    });

    // default to range given from resize / drop event
    let startTime = new Date(moment(eventInfo.event._instance.range.start));
    let endTime = new Date(moment(eventInfo.event._instance.range.end));

    // check if there was a time associated with previous event
    const previousStartTime = getTime(utcDate(matchingEvent.start));
    const previousEndTime = getTime(utcDate(matchingEvent.end));

    // if previous event was an allDay event and had time we want to preserve it
    // e.g. 6/3/2024 12:30pm to 6/4/2024 12:30pm, drag one day forward
    // should become 6/4/2024 12:30pm to 6/5/2024 12:30pm
    // allDay events initialize to midnight otherwise
    // disregard seconds for now
    if (matchingEvent.allDay && (previousStartTime || previousEndTime)) {
      // end date previously has had one day added so we need to remove it
      endTime = subtractDays(endTime, 1);
      startTime = utcDate(addHours(startTime, previousStartTime.hour));
      startTime = utcDate(addMinutes(startTime, previousStartTime.minute));
      endTime = utcDate(addHours(endTime, previousEndTime.hour));
      endTime = utcDate(addMinutes(endTime, previousEndTime.minute));
    }

    const updatedMatchingEvent = {
      title: matchingEvent.title,
      startTime,
      endTime,
      id: matchingEvent.id,
      description: matchingEvent.description ? matchingEvent.description : "",
      location: matchingEvent.location ? matchingEvent.location : "",
      lat: matchingEvent.lat ? matchingEvent.lat : "",
      lng: matchingEvent.lng ? matchingEvent.lng : "",
      type: matchingEvent.type ? matchingEvent.type : "",
    };

    if (eventInfo && updatedMatchingEvent) {
      // existing event should use original creator id
      // new one can use currentUserId
      const creatorUserId =
        matchingEvent && matchingEvent.creator
          ? matchingEvent.creator.id
          : userId;
      saveEvent({
        ...updatedMatchingEvent,
        tripId: trip_id,
        userId: creatorUserId,
      }).then((events) => {
        fetchEventsForTrip({ tripId: trip_id, callee: "edit" }).then(
          (events) => {
            setEvents(events);
          }
        );
      });
    }
  };

  // set calendar view depending on screen size and/or trip length
  // only called when user has not selected a calendar view manually
  const defaultCalendarView = useCallback(() => {
    if (screenIsSmall) {
      setCalendarView("timeGridDay");
    } else if (tripLengthInDays < 4) {
      setCalendarView("timeGridFourDay");
    } else if (tripLengthInDays < 7) {
      setCalendarView("timeGridWeek");
    } else {
      setCalendarView("dayGridMonth");
    }
  }, [screenIsSmall, tripLengthInDays]);

  useEffect(() => {
    if (places && !draggableInstantiated) {
      let draggableEl = document.getElementById("places-container");
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: (eventEl) => {
          let id = eventEl.dataset.id;
          let title = eventEl.getAttribute("title");

          return {
            id: id,
            title: title,
            color: null,
            create: true,
          };
        },
      });
      setDraggableInstantiated(true);
    }
  }, [places, draggableInstantiated]);

  // TO DO consider elevating setting of trip and events to parent Trip component
  // to smoothen inital load when switching between trips
  // refactoring calendar component to shared component could fix this as well
  // holding off for now since not that noticable
  useEffect(() => {
    if (trip_id) {
      fetchEventsForTrip({ tripId: trip_id, callee: "edit" }).then((events) => {
        setEvents(events);
      });
    }
  }, [trip_id, setEvents]);

  useEffect(() => {
    if (trips) {
      // TO DO refactor to trip
      const trip = trips.find((trips) => trips.id === trip_id);
      if (typeof trip === "undefined") {
        navigate("/home");
        setShowSnackbar(true);
      } else if (trip) {
        setTripTitle(trip.title);
        setStartDate(utcDate(trip.start_date));
        setEndDate(utcDate(trip.end_date));
        setTripLocation(trip.location);
        setTripLengthInDays(
          diffDays(utcDate(trip.end_date), utcDate(trip.start_date))
        );
        setIsPublic(trip.is_public);
      }
    }
  }, [
    trips,
    trip_id,
    setTripTitle,
    setEndDate,
    setStartDate,
    setTripLocation,
    setTripLengthInDays,
    navigate,
    setShowSnackbar,
  ]);

  // listen for trip length and screen size
  useEffect(() => {
    if (tripLengthInDays && !localStorage.getItem(trip_id))
      defaultCalendarView();
  }, [tripLengthInDays, screenIsSmall, defaultCalendarView, trip_id]);

  // once calendarView is set then determine firstDay
  // for fourDay and week views, make first day first day of trip
  // for month initialize to Sunday (0) since most expected
  // day works off visibleRange below
  useEffect(() => {
    if (calendarView === "timeGridFourDay" || calendarView === "timeGridWeek") {
      setFirstDay(getDay(startDate));
    } else {
      setFirstDay(0);
    }
    setTripInitialized(true); // last to ensure smooth loading
  }, [calendarView, startDate, setTripInitialized]);

  // actions
  const handleEventSelect = (eventInfo) => {
    setEventSelected(eventInfo);
  };

  useEffect(() => {
    if (eventSelected && !dialogOpen) {
      setDialogOpen(true);
    }
  }, [eventSelected, dialogOpen]);

  // https://stackoverflow.com/questions/37440408/how-to-detect-esc-key-press-in-react-and-how-to-handle-it
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setEventClicked(undefined);
      setDateClicked(undefined);
      setEventSelected(undefined);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    setTimeout(() => {
      calendarApi && calendarApi.updateSize();
    }, theme.transitions.duration.leavingScreen);
  }, [sidebarCollapsed, calendarApi]);

  return (
    // TO DO make work as grid for consistency
    <Box mt={2}>
      {tripInitialized && calendarView && typeof firstDay !== "undefined" ? (
        <>
          <CalendarAppBar
            calendarTitle={calendarTitle}
            setCalendarTitle={setCalendarTitle}
            calendarView={calendarView}
            setCalendarView={setCalendarView}
            setEventClicked={setEventClicked}
            setDialogOpen={setDialogOpen}
            currentDay={currentDay}
            setCurrentDay={setCurrentDay}
            startDate={startDate}
            endDate={endDate}
            calendarApi={calendarApi}
            editable={true}
            calendarViewOptions={EditCalendarOptions}
            isPublic={isPublic}
          />
          <Grid
            item
            xs={12}
            sx={{ minHeight: "725px", paddingBottom: "70px" }}
            ref={calendarContainerRef}
          >
            <FullCalendar
              timeZone="utc"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={null}
              initialView={calendarView}
              initialDate={startDate}
              firstDay={firstDay}
              // TO DO investigate prod dev date discrepencies further
              // for now implement ternary to check for dev vs. prod and add date accordingly
              validRange={{
                start: startDate.$d,
                end:
                  process.env.NODE_ENV === "development"
                    ? endDate.$d
                    : addDays(endDate.$d, 1),
              }}
              // make start day visible on mobile initially
              visibleRange={{
                start: startDate.$d,
                end: endDate.$d,
              }}
              editable={true}
              droppable={true}
              selectable={true}
              selectMirror={true}
              eventClick={handleEventClick}
              dateClick={handleDateClick}
              events={events}
              eventDrop={handleEventDropResize}
              eventResize={handleEventDropResize}
              // https://github.com/fullcalendar/fullcalendar-react/issues/47
              // see comment above handleEventReceive for more info
              // drop={handleEventReceive}
              ref={calendarRef}
              slotDuration="01:00:00"
              eventMouseEnter={(info) => handleEventMouseEnter(info)}
              eventMouseLeave={(info) => handleEventMouseLeave(info)}
              select={handleEventSelect}
              eventContent={(eventInfo) => {
                // https://codepen.io/arshaw/pen/ExPXjpY?editable=true&editors=001
                return {
                  html: eventInfo.event._def.extendedProps.customHtml,
                };
              }}
              // only show times when calendar view is set to day
              displayEventTime={calendarView === "dayGridMonth" ? true : false}
              views={{
                ...CustomCalendarViews,
              }}
              scrollTime="08:00:00"
              height={calendarContainerHeight}
              eventColor={eventBackgroundColorFallback}
            />
          </Grid>

          <EventDialog
            open={dialogOpen}
            handleClose={handleDialogClose}
            dateClicked={dateClicked}
            eventClicked={eventClicked}
            calendarView={calendarView}
            eventSelected={eventSelected}
            setEventClicked={setEventClicked}
            setEventSelected={setEventSelected}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </>
      ) : (
        ""
      )}
    </Box>
  );
};
