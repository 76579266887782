import { fetchUserAttributes, fetchAuthSession } from "aws-amplify/auth";

export const fetchOrCreateUser = async ({ email, firstName, lastName }) => {
  try {
    const response = await fetch(`/api/users`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        firstName,
        lastName,
      }),
    });
    const dbUser = await response.json();
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    setLocalStorageItem({ key: "user_id", value: dbUser.id });
    setLocalStorageItem({ key: "email", value: dbUser.email });
    setLocalStorageItem({ key: "first_name", value: dbUser.first_name });
    setLocalStorageItem({ key: "last_name", value: dbUser.last_name });
    setLocalStorageItem({ key: "session_id", value: idToken.payload.event_id });
    return "retrieved user from db";
  } catch (e) {
    console.log({ e });
  }
};

export const saveUserInfo = async ({ firstName, lastName, userId }) => {
  try {
    const response = await fetch(`/api/users/info`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        userId,
      }),
    });
    const dbUser = await response.json();
    setLocalStorageItem({ key: "first_name", value: dbUser.first_name });
    setLocalStorageItem({ key: "last_name", value: dbUser.last_name });
    return "user info updated";
  } catch (e) {
    console.log({ e });
  }
};

export const handleFetchUserAttributes = async () => {
  try {
    const userAttributes = await fetchUserAttributes();
    return { userAttributes };
  } catch (e) {
    console.log({ e });
  }
};

export const fetchTripOwner = async ({ userId }) => {
  try {
    const response = await fetch(`/api/users/${userId}`);
    const tripOwner = await response.json();
    return tripOwner;
  } catch (e) {
    console.log({ e });
  }
};

export const setLocalStorageItem = ({ key, value }) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.log({ e });
  }
};
