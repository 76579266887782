import { GoogleMapsAutocompleteWrapper } from "./";

export const ExploreSearch = ({
  loading,
  location,
  setLocation,
  lat,
  setLat,
  lng,
  setLng,
}) => {
  return (
    <GoogleMapsAutocompleteWrapper
      location={location}
      setLocation={setLocation}
      setLat={setLat}
      setLng={setLng}
      required={false}
      loading={loading}
      tripLatLng={{ lat, lng }}
      label="Search for places"
    />
  );
};
