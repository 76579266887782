import { createTheme } from "@mui/material/styles";

export const Theme = createTheme({
  palette: {
    primary: {
      main: "#081E2A",
    },
    events: {
      activity: "#73A2CE",
      reservation: "#729C7A",
      accomodation: "#ABE6F5",
      transit: "#FFD663",
      undefined: "#830902",
    },
    verifiedUser: "#0095f6",
  },
});

export const AmplifyTheme = {
  name: "pretty-princess",
  tokens: {
    colors: {
      background: {
        primary: { value: "hotpink" },
      },
      color: {
        primary: { value: "white" },
      },
    },
    // https://ui.docs.amplify.aws/react/theming/theme-provider
    components: {
      card: {
        backgroundColor: { value: "{colors.background.secondary}" },
        outlined: {
          borderColor: { value: "{colors.black}" },
        },
      },
      heading: {
        color: { value: "{colors.secondary[80]}" },
      },
      text: {
        color: { value: "{colors.primary[80]}" },
      },
      button: {
        backgroundColor: { value: "red" },
      },
      buttongroup: {
        backgroundColor: { value: "red" },
      },
      tabs: {
        borderColor: { value: "{colors.neutral.20}" },
        item: {
          color: { value: "{colors.blue.80}" },
          fontSize: { value: "{fontSizes.xl}" },
          fontWeight: { value: "{fontWeights.normal}" },

          _hover: {
            color: { value: "{colors.blue.60}" },
          },
          _focus: {
            color: { value: "{colors.blue.60}" },
          },
          _active: {
            color: { value: "{colors.red.80}" },
            borderColor: { value: "{colors.red.80}" },
            backgroundColor: { value: "{colors.red.10}" },
          },
          _disabled: {
            color: { value: "gray" },
            backgroundColor: { value: "transparent" },
          },
        },
      },
    },
  },
};
