import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useRef } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";

export const UploadButton = ({
  selectFileHandler,
  id,
  allowedFileInfo,
  label,
  style,
  icon,
  loading,
  setLoading,
  setAlert,
}) => {
  // Ref object to reference the input element
  const inputFile = useRef(null);

  // Function to reset the input element
  const handleReset = () => {
    if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
    }
  };

  const { fileTypes, fileSize } = allowedFileInfo;
  // disable when loading
  // or disabled
  // TO DO clean up
  const disableButton = loading ? true : false;
  return (
    <Box id={id} sx={{ margin: "auto" }}>
      <LoadingButton
        component="label"
        loading={loading}
        sx={{ margin: "auto", ...style }}
        disabled={disableButton}
        endIcon={icon ? icon : <CloudUploadIcon />}
        onClick={(e) => e.stopPropagation()}
      >
        {label ? <Typography>{label}</Typography> : ""}

        <StyledVisuallyHiddenInput
          type="file"
          // TO DO resolve edge case where selecting file 2x doesn't trigger upload window
          // since technically not considered "change", i.e. it's the same file as before
          // a reset button to reset the ref is one idea
          onChange={(event) => {
            const uploadedFile = event.target.files[0];
            const uploadedFileSize = uploadedFile && uploadedFile.size;
            if (
              fileTypes.includes(uploadedFile.type) &&
              uploadedFileSize < fileSize.kb
            ) {
              setAlert(false);
              setLoading(true);
              selectFileHandler({ file: uploadedFile });
            } else {
              let alertMsg = `File type can only be ${JSON.stringify(
                fileTypes
              )}`;
              if (uploadedFileSize > fileSize.kb)
                alertMsg = `File size exceeds limit [${fileSize.readable}]`;
              setAlert({
                severity: "error",
                message: alertMsg,
              });
              handleReset();
            }
          }}
          ref={inputFile}
        />
      </LoadingButton>
    </Box>
  );
};

const StyledVisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
