import { CircularProgress } from "@mui/material";
import { EmptyState } from "./";

export const Loader = ({ styleOverrides }) => {
  return (
    <EmptyState
      styleOverrides={styleOverrides}
      children={<CircularProgress sx={{ margin: "auto 0" }} />}
    ></EmptyState>
  );
};
