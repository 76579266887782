import {
  diffDays,
  addDays,
  addHours,
  getHour,
  utcDate,
  diffEvent,
} from "./date_utils";
import { Theme } from "../Theme";
import { dayInMs } from "../constants";

let eventGuid = 0;

export const createEventId = () => {
  return String(eventGuid++);
};

export const dateToEvent = (date) => {
  if (date) {
    const { $y, $M, $D } = date;
    return `${$y}-${$M + 1}-${$D + 1}`;
  }
  return null;
};

export const fetchEventsForTrip = async ({ tripId, callee }) => {
  try {
    const response = await fetch(`/api/events/${tripId}`);
    const events = await response.json();
    if (events && events.length) {
      const adaptedEvents =
        events &&
        events.map((event) => {
          // dates are exclusive in fullCalendar
          // for events that are all day need to add a day so they render on final day...
          // we only want to apply this to the calendar, not the end date picker
          // so created a new property "endPicker" for the picker since FC uses "end" property
          // see more info here: https://fullcalendar.io/docs/nextDayThreshold
          const moreThanOrEqual24Hrs =
            diffEvent(event.start_time, event.end_time) >= dayInMs
              ? true
              : false;

          // all dates more than or equal 24 hours
          // should show on next day
          // except for midnight date 1 to midnight of next immediate date
          // test cases
          // 6/3/2024 12:00 AM - 6/4/2024 12:00 AM should render on 6/3 only
          // 6/3/2024 12:00 PM - 6/4/2024 12:00 PM should render on 6/3 and 6/4
          // 6/3/2024 12:00 AM - 6/5/2024 12:00 AM should render on 6/3 and 6/4 but not 6/5
          const showOnNextDay =
            moreThanOrEqual24Hrs &&
            !(
              event.start_time.includes("00:00:00") &&
              event.end_time.includes("00:00:00")
            )
              ? true
              : false;
          const endTimeForFc = showOnNextDay
            ? addDays(event.end_time, 1)
            : event.end_time;

          let { title, description, location } = event;
          title = event.is_favorite
            ? title +
              '<i class="bi bi-heart-fill" style="margin-left:10px"></i>'
            : title;
          let html = "";
          if (callee === "edit") {
            html = `<span style="cursor:pointer !important; overflow: hidden;">
              ${title} 
              </span>`;
          } else if (callee === "share") {
            html = `
            <span style="cursor:pointer !important">
                ${title} 
                <br />
                <a href="http://www.google.com/search?q=${location}" target="_blank" class="inline-link">
                  ${
                    location
                      ? location +
                        '<i class="bi bi-box-arrow-up-right" style="margin-left:10px"></i>'
                      : ""
                  }
                </a>
                ${location ? "<br />" : ""}
                <span>${description ? description : ""}</span>
              </span>`;
          }
          return {
            start: event.start_time,
            endPicker: event.end_time,
            end: endTimeForFc,
            title: event.title,
            description: event.description,
            type: event.type,
            id: event.id,
            color: Theme.palette.events[event.type || "undefined"],
            displayEventTime: false,
            location: event.location,
            lat: event.lat,
            lng: event.lng,
            allDay: moreThanOrEqual24Hrs,
            creator: {
              firstName: event.first_name,
              lastName: event.last_name,
              id: event.user_id,
            },
            favorite: event.is_favorite,
            customHtml: html,
          };
        });
      return adaptedEvents || [];
    }
    return [];
  } catch (e) {
    console.log({ e });
  }
};

export const saveEvent = async ({
  title,
  startTime,
  endTime,
  tripId,
  userId,
  description,
  id,
  type,
  location,
  lat,
  lng,
  favorite,
}) => {
  try {
    const response = await fetch(`/api/events`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        startTime,
        endTime,
        tripId,
        userId,
        description,
        id,
        type,
        location,
        lat,
        lng,
        favorite,
      }),
    });
    const event = await response.json();
    return event;
  } catch (e) {
    console.log({ e });
  }
};

// TO DO make anonymous function
export const deleteEvent = async function deleteEvent({ id, tripId }) {
  try {
    const response = await fetch(`/api/events/delete`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        tripId,
      }),
    });
    const event = await response.json();
    return event;
  } catch (e) {
    console.log({ e });
  }
};

export const toggleFavoriteEvent = async ({ id, isFavorite }) => {
  try {
    const response = await fetch(`/api/events/togglefavorite`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        isFavorite,
      }),
    });
    let result = await response.json();
    return result;
  } catch (e) {
    console.log({ e });
  }
};

export const copyEvent = ({ event, originalTrip, newTrip }) => {
  // when copying event
  // all dates should be adjusted relative to originalTripStartDate
  // event start / endTime
  // we also want to delete properties like id, tripId, userId

  // start date first
  const originalTripStartDate = originalTrip.start_date; //snake case
  // number of days original event was from original start date
  const daysEventStartFromOriginalStartDate =
    diffDays(originalTripStartDate, event.start) - 1;
  const newTripStartDate = newTrip.start_date; //snake case
  const newEventStartDateTime = utcDate(
    addHours(
      addDays(newTripStartDate, daysEventStartFromOriginalStartDate),
      getHour(utcDate(event.start))
    )
  );
  // repeat above for end
  const daysEventEndFromOriginalStartDate =
    diffDays(originalTripStartDate, event.end) - 1;
  const newEventEndDateTime = utcDate(
    addHours(
      addDays(newTripStartDate, daysEventEndFromOriginalStartDate),
      getHour(utcDate(event.end))
    )
  );

  delete event.id;
  event.startTime = newEventStartDateTime;
  delete event.start;
  event.endTime = newEventEndDateTime;
  delete event.end;
  event.tripId = newTrip.id;
  event.userId = localStorage.getItem("user_id");
  delete event.creator;

  return event;
};
