import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchTripsForUser, saveTrip } from "../../utils/trip_utils";

export const SaveTripButton = ({
  title,
  startDate,
  endDate,
  userId,
  tripId,
  location,
  lat,
  lng,
  description,
  setTrips,
  setTripAlert,
  fullWidth,
}) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() =>
        saveTrip({
          title,
          startDate,
          endDate,
          userId,
          tripId,
          location,
          lat,
          lng,
          description,
        }).then((result) => {
          // TO DO remove anti-pattern of saving and then retrieving
          // saving should perform retrieving
          if (result) {
            fetchTripsForUser({ userId }).then((trips) => {
              setTrips(trips);
            });

            if (setTripAlert) {
              setTripAlert({
                severity: "success",
                message: "Trip updated",
              });
              setTimeout(() => {
                setTripAlert(false);
              }, [5000]);
            }

            if (result && tripId !== result.id) {
              navigate(`/trip/${result.id}`);
            }
          }
        })
      }
      fullWidth={fullWidth}
      variant="contained"
      disabled={title && location && startDate && endDate ? false : true}
    >
      Save
    </Button>
  );
};
