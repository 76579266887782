import {
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";

export const ToggleViewBar = ({
  items,
  view,
  setView,
  setPage,
  setRowsPerPage,
  title,
  callee,
}) => {
  const currentUserId = localStorage.getItem("user_id");

  const handleView = (event, newView) => {
    event.stopPropagation();
    if (newView !== null) {
      setView(newView);
      setPage && setPage(0);
      setRowsPerPage && setRowsPerPage(6);
      if (currentUserId && callee) {
        localStorage.setItem(`${callee}_view`, newView);
      }
    }
  };
  return (
    <Grid item xs={12}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        {title}
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleView}
          aria-label="toggle view"
        >
          {items.map(({ value, icon, tooltip, disabled = false }) => {
            return (
              <Tooltip title={tooltip} key={`toggle-view-button-${value}`}>
                <span>
                  <ToggleButton
                    value={value}
                    aria-label={value}
                    size="small"
                    disabled={disabled}
                  >
                    {icon}
                  </ToggleButton>
                </span>
              </Tooltip>
            );
          })}
        </ToggleButtonGroup>
      </Stack>
    </Grid>
  );
};
