import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TextEditorDialogTitle } from "./";
import { SaveTripDescriptionButton } from "../Buttons";
export const TextEditorDialog = ({
  open,
  handleClose,
  children,
  content,
  tripId,
  loading,
  setLoading,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      PaperProps={{
        component: "form",
      }}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
    >
      <TextEditorDialogTitle
        title="Add Description"
        handleClose={handleClose}
        loading={loading}
      />
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SaveTripDescriptionButton
          tripId={tripId}
          content={content}
          handleClose={handleClose}
          loading={loading}
          setLoading={setLoading}
        />
      </DialogActions>
    </Dialog>
  );
};
