import { styled } from "@mui/material/styles";
import { useRef } from "react";
import { Avatar, Badge, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// TO DO refactor to DRY up this file by combining it with UploadButton
// UploadButton should work as loading button or avatar
// for now fine for Beta
export const UploadAvatar = ({
  selectFileHandler,
  id,
  allowedFileInfo,
  label,
  style,
  icon,
  loading,
  setLoading,
  setAlert,
}) => {
  // Ref object to reference the input element
  const inputFile = useRef(null);

  // Function to reset the input element
  const handleReset = () => {
    if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
    }
  };

  const { fileTypes, fileSize } = allowedFileInfo;

  return (
    <Tooltip title="Add profile picture">
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={
          icon ? (
            icon
          ) : (
            <AddCircleIcon
              sx={{
                height: "22px",
                width: "22px",
              }}
            />
          )
        }
        component="label"
        sx={{ cursor: "pointer" }}
      >
        <Avatar component="label" sx={{ cursor: "pointer" }}>
          {label}

          <StyledVisuallyHiddenInput
            type="file"
            onChange={(event) => {
              const uploadedFile = event.target.files[0];
              const uploadedFileSize = uploadedFile && uploadedFile.size;
              if (
                uploadedFile &&
                fileTypes.includes(uploadedFile.type) &&
                uploadedFileSize < fileSize.kb
              ) {
                setAlert(false);
                setLoading(true);
                selectFileHandler({ file: uploadedFile });
              } else {
                let alertMsg = `File type can only be ${JSON.stringify(
                  fileTypes
                )}`;
                if (uploadedFileSize > fileSize.kb)
                  alertMsg = `File size exceeds limit [${fileSize.readable}]`;
                setAlert({
                  severity: "error",
                  message: alertMsg,
                });
                handleReset();
              }
            }}
            ref={inputFile}
          />
        </Avatar>
      </Badge>
    </Tooltip>
  );
};

const StyledVisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
