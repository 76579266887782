import { useState, useEffect, useContext, useCallback } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Step,
  Stepper,
  StepLabel,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { EventDialogTitle } from "./";
import { EventFields1, EventFields2 } from "../";
import { utcDate, addHours } from "../../utils/date_utils";
import { AppContext } from "../../contexts";
import { useParams } from "react-router-dom";
import { EventFileDisplay } from "../EventFileDisplay";
import {
  EventDialogStep1Explanation,
  EventDialogStep2Explanation,
} from "../../constants";
import { handleNext, handleBack } from "../../utils/dialog_utils";
import { RequiredFieldsTooltip } from "../../constants";
import { DeleteEventButton, SaveEventButton } from "../Buttons";

export const EventDialog = ({
  open,
  handleClose,
  dateClicked,
  eventClicked,
  calendarView,
  eventSelected,
  setEventClicked,
  setEventSelected,
  activeStep,
  setActiveStep,
}) => {
  const {
    setEventFileStr,
    setEventFileExtension,
    userId,
    setEvents,
    trips,
    collaborators,
  } = useContext(AppContext);
  const { trip_id } = useParams();
  const theme = useTheme();
  // https://mui.com/material-ui/customization/breakpoints/#api
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const screenIsMediumOrUp = useMediaQuery(theme.breakpoints.up("md"));

  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(true);
  const [title, setTitle] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [alert, setAlert] = useState(false);
  const [location, setLocation] = useState("");
  const [lat, setLat] = useState(undefined);
  const [lng, setLng] = useState(undefined);
  const [fileDisplayBackdropOpen, setFileDisplayBackdropOpen] = useState(false);
  const [tripLatLng, setTripLatLng] = useState(undefined);
  const [creator, setCreator] = useState(undefined);
  const [favorite, setFavorite] = useState(false);

  // newEvent should be true when date NOT event clicked
  // or add button is clicked
  // or event is selected
  const newEvent = !(eventClicked && eventClicked.id) || eventSelected;
  const reinitializeState = useCallback(() => {
    setTitle("");
    setStartTime(null);
    setEndTime(null);
    setType("");
    setDescription("");
    setAlert(false);
    setEventFileStr(undefined);
    setEventFileExtension(undefined);
    setLoading(false);
    setLocation("");
    setLat(undefined);
    setLng(undefined);
    setEditable(true);
    setCreator(undefined);
    setFavorite(false);
  }, [setEventFileExtension, setEventFileStr]);

  useEffect(() => {
    if (dateClicked) {
      setTitle("");
      const { allDay, date } = dateClicked;
      // month view, initialize eventTime to 12 - 1
      if (calendarView === "dayGridMonth") {
        setStartTime(utcDate(addHours(date, 12)));
        setEndTime(utcDate(addHours(date, 13)));
      } else if (allDay) {
        // grid view and all day
        setStartTime(utcDate(addHours(date, 0)));
        setEndTime(utcDate(addHours(date, 24)));
      } else {
        //otherwise time clicked + 1 hour
        setStartTime(utcDate(date));
        setEndTime(utcDate(addHours(date, 1)));
      }
      setDescription("");
      setEditable(true); //should be editable when date clicked
    }
  }, [dateClicked, calendarView]);

  useEffect(() => {
    if (eventClicked) {
      setTitle(eventClicked.title || "");
      setStartTime(utcDate(eventClicked.start) || null);
      // account for createEventButton
      setEndTime(
        utcDate(
          eventClicked.endPicker ? eventClicked.endPicker : eventClicked.end
        ) || null
      );
      setDescription(eventClicked.description || "");
      setType(eventClicked.type || "");
      setLocation(eventClicked.location || "");
      setLat(eventClicked.lat || undefined);
      setLng(eventClicked.lng || undefined);
      // should always be editable when createEventButton is clicked
      // or screen is medium or bigger
      if (
        (eventClicked.callee && eventClicked.callee === "createEventButton") ||
        screenIsMediumOrUp
      ) {
        setEditable(true);
      }
      setCreator(eventClicked.creator || undefined);
      setFavorite(eventClicked.favorite || false);
    }
  }, [eventClicked, screenIsMediumOrUp]);

  useEffect(() => {
    if (eventSelected) {
      setTitle("");
      setStartTime(utcDate(eventSelected.start) || null);
      setEndTime(utcDate(eventSelected.end) || null);
      setDescription("");
      setType("");
      setLocation("");
      setLat(undefined);
      setLng(undefined);
      setEditable(true);
      setCreator(undefined);
      setFavorite(false);
    }
  }, [eventSelected]);

  // once closed, reinitialize state
  useEffect(() => {
    if (!open) {
      reinitializeState();
    }
  }, [open, reinitializeState]);

  useEffect(() => {
    if (trips) {
      // TO DO refactor to trip
      const trip = trips.find((trips) => trips.id === trip_id);
      if (trip) {
        setTripLatLng({ lat: trip.lat, lng: trip.lng });
      }
    }
  }, [trips, trip_id]);

  // race condition
  // make sure start and end times are defined before rendering modal
  return startTime && endTime ? (
    <Dialog
      open={open}
      onClose={() => {
        if (!loading) {
          handleClose();
          // edge case if had previously viewed attachment associated with event
          // and close backdrop instead of EventFileDisplay
          // and then viewed another event, the modal would not render properly
          // calling this here fixes that
          setFileDisplayBackdropOpen(false);
        }
      }}
      fullScreen={fullScreen}
      PaperProps={{
        component: "form",
        sx: { position: "relative" },
      }}
    >
      {collaborators && (
        <EventDialogTitle
          newEvent={newEvent}
          loading={loading}
          handleClose={handleClose}
          creator={
            creator && collaborators.find((c) => c.userId === creator.id)
          }
          eventClicked={eventClicked}
          favorite={favorite}
          setFavorite={setFavorite}
        />
      )}
      <DialogContent>
        <Grid container spacing={2}>
          {alert ? (
            <Grid item xs={12}>
              <Alert severity={alert.severity} onClose={() => setAlert(false)}>
                {alert.message}
              </Alert>
            </Grid>
          ) : (
            ""
          )}

          <Grid item xs={12}>
            <Stepper activeStep={activeStep}>
              <Step>
                <StepLabel>{EventDialogStep1Explanation}</StepLabel>
              </Step>
              <Step>
                <StepLabel
                  optional={<Typography variant="caption">Optional</Typography>}
                >
                  {EventDialogStep2Explanation}
                </StepLabel>
              </Step>
            </Stepper>
          </Grid>

          {activeStep === 0 ? (
            <Grid item xs={12}>
              <EventFields1
                loading={loading}
                title={title}
                setTitle={setTitle}
                startTime={startTime}
                setStartTime={setStartTime}
                endTime={endTime}
                setEndTime={setEndTime}
              />
            </Grid>
          ) : (
            ""
          )}

          {activeStep === 1 ? (
            <Grid item xs={12}>
              <EventFields2
                loading={loading}
                setLoading={setLoading}
                title={title}
                startTime={startTime}
                endTime={endTime}
                type={type}
                setType={setType}
                description={description}
                setDescription={setDescription}
                setAlert={setAlert}
                location={location}
                setLocation={setLocation}
                setLat={setLat}
                setLng={setLng}
                newEvent={!(eventClicked && eventClicked.id)}
                eventId={eventClicked ? eventClicked.id : null}
                setFileDisplayBackdropOpen={setFileDisplayBackdropOpen}
                tripLatLng={tripLatLng}
                setEventClicked={setEventClicked}
                setEventSelected={setEventSelected}
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {activeStep === 0 ? (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            {newEvent ? (
              ""
            ) : (
              <DeleteEventButton
                editable={editable}
                eventClicked={eventClicked}
                handleClose={handleClose}
                loading={loading}
                setLoading={setLoading}
                setEvents={setEvents}
                tripId={trip_id}
              />
            )}
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              sx={{ width: "100%" }}
            >
              <SaveEventButton
                title={title}
                startTime={startTime}
                endTime={endTime}
                description={description}
                location={location}
                lat={lat}
                lng={lng}
                type={type}
                favorite={favorite}
                loading={loading}
                setLoading={setLoading}
                setAlert={setAlert}
                editable={editable}
                tripId={trip_id}
                userId={userId}
                eventClicked={eventClicked}
                setEvents={setEvents}
                handleClose={handleClose}
                variant={null}
                label="Save"
              />
              <Tooltip
                title={
                  title && startTime && endTime ? "" : RequiredFieldsTooltip
                }
              >
                <span>
                  <Button
                    onClick={() => handleNext(activeStep, setActiveStep)}
                    variant="contained"
                    mt={1}
                    disabled={
                      title && startTime && endTime && !loading ? false : true
                    }
                  >
                    Next
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
        ) : (
          ""
        )}

        {activeStep === 1 ? (
          <Stack direction="row" spacing={1}>
            <Button
              onClick={() => handleBack(activeStep, setActiveStep)}
              disabled={loading}
            >
              Back
            </Button>
            <SaveEventButton
              title={title}
              startTime={startTime}
              endTime={endTime}
              description={description}
              location={location}
              lat={lat}
              lng={lng}
              type={type}
              favorite={favorite}
              loading={loading}
              setLoading={setLoading}
              setAlert={setAlert}
              editable={editable}
              tripId={trip_id}
              userId={userId}
              eventClicked={eventClicked}
              setEvents={setEvents}
              handleClose={handleClose}
              variant="contained"
              label="Save"
            />
          </Stack>
        ) : (
          ""
        )}
      </DialogActions>

      {/* 
        this needed to be moved here to resolve  
        zIndex / overlap issues b/w eventfile backdrop and dialog action buttons
        I was never able to identify the root cause issue so I refactored the code 
        to this and it worked :) 
      */}
      {fileDisplayBackdropOpen ? (
        <EventFileDisplay
          open={fileDisplayBackdropOpen}
          setOpen={setFileDisplayBackdropOpen}
        />
      ) : (
        ""
      )}
    </Dialog>
  ) : (
    ""
  );
};
