import { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TripFields1 } from "./";
import { AppContext } from "../contexts";
import { useParams } from "react-router-dom";
import { utcDate } from "../utils/date_utils";
import { SaveTripButton } from "./Buttons";
import { TipTapWrappers } from "./Rte/TipTapWrappers";

export const TripDetail = ({ expanded, setExpanded, sidebarCollapsed }) => {
  const { trips, userId, setTrips } = useContext(AppContext);
  const { trip_id } = useParams();

  const theme = useTheme();
  const screenIsMediumOrUp = useMediaQuery(theme.breakpoints.up("md"));

  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [lat, setLat] = useState(undefined);
  const [lng, setLng] = useState(undefined);
  const [tripAlert, setTripAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (trips) {
      // TO DO refactor to trip
      const trip = trips.find((trips) => trips.id === trip_id);
      if (trip) {
        setTitle(trip.title);
        setStartDate(utcDate(trip.start_date));
        setEndDate(utcDate(trip.end_date));
        setLocation(trip.location);
        setDescription(trip.description || ""); // optional fallback to empty string
        setLat(trip.lat || ""); // optional fallback to empty string
        setLng(trip.lng || ""); // optional fallback to empty string
      }
    }
  }, [trips, trip_id]);

  // on resize ensure expanded on desktop, collapsed on mobile
  useEffect(() => {
    if (screenIsMediumOrUp) {
      setExpanded("overview");
    } else {
      setExpanded("");
    }
  }, [screenIsMediumOrUp, setExpanded]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Accordion
        expanded={expanded === "overview"}
        onChange={handleChange("overview")}
        sx={sidebarCollapsed ? { visibility: "hidden" } : {}}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Overview</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {tripAlert ? (
              <Grid item xs={12} mb={2}>
                <Alert
                  severity={tripAlert.severity}
                  onClose={() => setTripAlert(false)}
                >
                  {tripAlert.message}
                </Alert>
              </Grid>
            ) : (
              ""
            )}

            {startDate && endDate ? (
              <>
                <TripFields1
                  title={title}
                  setTitle={setTitle}
                  location={location}
                  setLocation={setLocation}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setLat={setLat}
                  setLng={setLng}
                  description={description}
                  setDescription={setDescription}
                  callee="detail"
                />

                <TipTapWrappers
                  content={description}
                  setContent={setDescription}
                  tripId={trip_id}
                  loading={loading}
                  setLoading={setLoading}
                  callee="tripdetail"
                  currentUserIsEditor={true}
                />
              </>
            ) : (
              ""
            )}
            <Grid item xs={12} md={6} mt={2}>
              <SaveTripButton
                title={title}
                startDate={startDate}
                endDate={endDate}
                userId={userId}
                tripId={trip_id}
                location={location}
                lat={lat}
                lng={lng}
                description={description}
                setTrips={setTrips}
                setTripAlert={setTripAlert}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
